import styled from 'styled-components'

const Domain = styled.div`
  padding-top: 60px;
  width: 100%;
  max-width: 100%;
  overflow: hidden;

  p {
    word-break: break-word;
  }
`

Domain.section = styled.div`
  padding-bottom: 30px;
`
Domain.bold = styled.span`
  font-weight: 700;
  font-size: 1.1em;
`
Domain.decimalList = styled.ol`
  list-style-type: decimal-leading-zero;
`
Domain.anchor = styled.span`
  display: block;
  height: 82px;
  margin-top: -82px;
  visibility: hidden;
`
export default Domain
