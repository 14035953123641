import React from 'react'

import Layout from '../../components/layout'
import TermsLayout from '../../components/layout/terms-layout'
import DomainRegistration from '../../components/domain-registration'
import SEO from '../../components/seo'

const IndexPage = () => {
  return (
    <Layout isWhiteHeader paddingTop>
      <SEO
        title='Domain Registration Agreement'
        keywords={[
          `web hosting`,
          `domain names`,
          `web site`,
          `search engine optimization`,
          `hosting`,
          `servers`
        ]}
        pageType={'company_info'}
        flowType={'none'}
      />
      <TermsLayout>
        <DomainRegistration />
      </TermsLayout>
    </Layout>
  )
}

export default IndexPage
